import React from "react";

const Services = () => {
  return (
    <div className="bg-white" id="services">
      <div className="max-w-7xl mx-auto px-4 pt-20 md:pt-28 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-16 textbig pb-20">Üsi Dienstleistungen</h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Service Card 1 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./ser1.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Sterbebegleitung
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Hypnose hilft dir mit positiven Gedanken und Bildern unerwünschte Gewohnheiten und
              Verhaltensmuster zu ändern. Positive Gedanken und Bilder werden im Unterbewusstsein
              verankert. Erlange Selbstvertrauen und Selbstliebe.
            </p>
            <button className="font-medium rounded-full buttonservice">
              zur Hypnose
            </button>
          </div>
          {/* Service Card 2 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle" >


            <img
              src="./ser2.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p className="fontiservicestitle pt-3 pb-3">
              Hypnose
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Hypnose hilft dir mit positiven Gedanken und Bildern unerwünschte Gewohnheiten und
              Verhaltensmuster zu ändern. Positive Gedanken und Bilder werden im Unterbewusstsein
              verankert. Erlange Selbstvertrauen und Selbstliebe.
            </p>
            <button className="font-medium rounded-full buttonservice">
              zur Hypnose
            </button>
          </div>

          {/* Service Card 3 */}
          <div className="bg-white p-8 border border-gray-100 cardstyle">
            <img
              src="./ser3.png"
              alt="Hypnosis Session"
              className="imageservice"
            />
            <p
              className="fontiservicestitle pt-3 pb-3"
            >
              Reiki-Behandlung
            </p>
            <p className="text-gray-600 leading-relaxed paragrafi">
              Hypnose hilft dir mit positiven Gedanken und Bildern unerwünschte Gewohnheiten und
              Verhaltensmuster zu ändern. Positive Gedanken und Bilder werden im Unterbewusstsein
              verankert. Erlange Selbstvertrauen und Selbstliebe.
            </p>
            <button className="font-medium rounded-full buttonservice">
              zur Hypnose
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services; 