import Navbar from "../components/navbar";
import Hero from "../components/hero";
import Services from "../components/services";
import Contact from "../components/contact";
import About from "../components/about";
import Testimonials from "../components/testimonial";
import Footer from "../components/footer";
import React from "react";

const home = () => {
  return (
    <main className="min-h-screen">
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
    </main>
  );
};

export default home;