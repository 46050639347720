import { useState } from "react";
import { Menu, X } from "lucide-react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { name: "Heim", href: "#" },
    { name: "Über Üs", href: "#about" },
    { name: "Üsi Dienstleistungen", href: "#services" },
    { name: "Das säge üsi Kunde", href: "#testimonials" },
  ];
  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="fixed w-full bg-white/90 backdrop-blur-sm z-50 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img src="./logo.png" alt="logo" className="logo" />
            <span className="text-xl font-semibold textbig">ESDARF LEICHSTEIN</span>
          </div>


          {/* Desktop menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-8">
              {navItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-[#2C2C2C] hover:text-[#2C2C2C] transition-colors duration-200 textbig"
                >
                  {item.name}
                </a>
              ))}
              <a
                href="#contact"
                className="textbig inline-flex items-center px-4 py-2 border border-[#CDCDCF] text-[#2C2C2C] hover:bg-white hover:text-[#2C2C2C] rounded-full transition-colors duration-200"
              >
               Kontaktier üs
              </a>
            </div>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-secondary hover:text-primary"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onClick={handleLinkClick}
                className="block px-3 py-2 text-[#2C2C2C]  hover:text-[#2C2C2C]  transition-colors duration-200 textbig"
              >
                {item.name}
              </a>
            ))}
            <a
              href="#contact"  onClick={handleLinkClick}
              className="textbig block px-3 py-2 text-[#2C2C2C]  hover:text-[#2C2C2C]  transition-colors duration-200"
            >
              Kontaktier üs
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;